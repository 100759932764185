export const getLocaleNumberString = (
  value: number | string,
  fractionDigits = 2,
  locale = 'en-US',
): string => {
  let _value = value

  if (typeof _value === 'string') {
    _value = parseFloat(_value)
  }

  if (locale === 'ja') {
    return `${value}`
  }

  return _value.toLocaleString(locale, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })
}
