import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { SpringValue } from 'react-spring'
import { CSSObject } from '@chakra-ui/react'

import { useCarouselData } from '@/features/landing/constants/carousel'
import { createCtx } from '@/lib/utils/context'

export type CarouselChildProps = {
  style?: Record<string, SpringValue>
}

export type CarouselDatum = {
  title: string
  subject?: string
  attendeeCount?: number
  image: string | StaticImageData
  sx?: CSSObject
  link?: Link
}

export type CarouselContext = {
  index: number
  setIndex: React.Dispatch<React.SetStateAction<number>>
  carouselData: CarouselDatum[]
  selectedData: CarouselDatum
}

export type CarouselContextProviderProps = {
  children: React.ReactNode
  initialIndex?: number
  autoRotate?: boolean
}

const carouselContext = createCtx<CarouselContext>()
const Provider = carouselContext[1]
export const useCarouselContext: () => CarouselContext = carouselContext[0]

export const CarouselContextProvider = ({
  children,
  initialIndex = 0,
  autoRotate = false,
}: CarouselContextProviderProps): React.ReactElement => {
  const router = useRouter()
  const [index, setIndex] = useState<number>(initialIndex)

  const carouselData = useCarouselData()
  const selectedData = carouselData[index]
  const length = carouselData.length

  useEffect(() => {
    if (!router.query.service) return

    const _index = carouselData.findIndex((datum) => {
      if (datum.link?.query) {
        return datum.link.query.service === router.query.service
      }

      return false
    })

    if (_index < 0 || index === _index) return

    setIndex(_index)
  }, [])

  useEffect(() => {
    if (autoRotate) {
      const timeOut = setTimeout(() => {
        setIndex((index) => Math.abs(index + 1) % length)
      }, 7000)

      return () => clearTimeout(timeOut)
    }
  }, [index, length, autoRotate])

  const contextValue = {
    index,
    setIndex,
    carouselData,
    selectedData,
  }

  return <Provider value={contextValue}>{children}</Provider>
}
