import { useTranslation } from 'next-i18next'

import { CarouselDatum } from '@/features/landing/components/Carousel'

import Image1 from '@/images/landing/legal-seminar-experience/1.png'
import Image2 from '@/images/landing/legal-seminar-experience/2.png'
import Image3 from '@/images/landing/legal-seminar-experience/3.png'
import Image4 from '@/images/landing/legal-seminar-experience/4.png'
import Image5 from '@/images/landing/legal-seminar-experience/5.png'
import Image6 from '@/images/landing/legal-seminar-experience/6.png'

export const useCarouselData = (): CarouselDatum[] => {
  const { t } = useTranslation('landing')

  return [
    {
      title: t('legalSeminarExp.contents.0.title'),
      subject: t('legalSeminarExp.contents.0.subject'),
      attendeeCount: 1500,
      image: Image1,
    },
    {
      title: t('legalSeminarExp.contents.1.title'),
      subject: t('legalSeminarExp.contents.1.subject'),
      attendeeCount: 100,
      image: Image2,
    },
    {
      title: t('legalSeminarExp.contents.2.title'),
      subject: t('legalSeminarExp.contents.2.subject'),
      attendeeCount: 100,
      image: Image3,
    },
    {
      title: t('legalSeminarExp.contents.3.title'),
      attendeeCount: 100,
      image: Image4,
    },
    {
      title: t('legalSeminarExp.contents.4.title'),
      subject: t('legalSeminarExp.contents.4.subject'),
      attendeeCount: 100,
      image: Image5,
    },
    {
      title: t('legalSeminarExp.contents.5.title'),
      subject: t('legalSeminarExp.contents.5.subject'),
      image: Image6,
    },
  ]
}
