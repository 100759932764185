import { useRef, useEffect } from 'react'
import { VStack, Flex } from '@chakra-ui/react'
import { useSprings } from 'react-spring'
import { useDrag } from 'react-use-gesture'

import { DEFAULT_SPRING_CONFIG } from '@/lib/springComponent'

import { useCarouselContext } from './CarouselContext'
import CarouselBar from './CarouselBar'
// import CarouselControl from './CarouselControl'
import CarouselItem from './CarouselItem'

interface Props {
  id?: string
}

function Carousel({ id }: Props): React.ReactElement {
  const containerRef = useRef<HTMLDivElement>(null)

  const { index, setIndex, carouselData } = useCarouselContext()

  const [springs, springsApi] = useSprings(carouselData.length, (i: number) => {
    return {
      translate3d: `calc(${index * -100}% + 0px), 0, 0`,
      scale: index === i ? 1 : 0.9,
      config: DEFAULT_SPRING_CONFIG,
    }
  })

  const bind = useDrag(
    ({ active, movement: [mx], direction: [xDir], cancel }) => {
      if (!containerRef.current) return

      const boundingClientRect = containerRef.current.getBoundingClientRect()

      if (active && Math.abs(mx) > boundingClientRect.width / 3) {
        cancel()

        setIndex((currentIndex: number) =>
          (currentIndex + (xDir > 0 ? -1 : 1)).clamp(
            0,
            carouselData.length - 1,
          ),
        )
      }

      springsApi.start((i) => {
        if (i < index - 1 || i > index + 1) return { scale: 0.9 }

        return {
          translate3d: `calc(${index * -100}% + ${active ? mx : 0}px), 0, 0`,
          scale: active
            ? 1 - (Math.abs(mx) / boundingClientRect.width) * 0.7
            : index === i
            ? 1
            : 0.9,
        }
      })
    },
    { axis: 'x' },
  )

  useEffect(() => {
    springsApi.start((i: number) => {
      return {
        translate3d: `calc(${index * -100}% + 0px), 0, 0`,
        scale: index === i ? 1 : 0.9,
      }
    })
  }, [index])

  const length = carouselData.length

  return (
    <VStack id={id} sx={{ alignItems: 'center', w: 'full' }}>
      {/*<CarouselControl
        length={length}
        index={index}
        title={selectedData.title}
        description={selectedData.description}
        onChange={setIndex}
      />*/}
      <Flex
        {...bind()}
        ref={containerRef}
        sx={{
          w: 'full',
          overflowX: 'visible',
          cursor: 'grab',
          '&:active': { cursor: 'grabing' },
          '& > *': {
            flex: '1 0 100%',
          },
          overscrollBehaviorX: 'contain',
          touchAction: 'pan-y',
        }}
      >
        {carouselData.map((datum, idx) => {
          return <CarouselItem key={`${idx}`} style={springs[idx]} {...datum} />
        })}
      </Flex>
      <CarouselBar length={length} index={index} onClick={setIndex} />
    </VStack>
  )
}

export default Carousel
