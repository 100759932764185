import { Text, Stack, CSSObject, AspectRatio } from '@chakra-ui/react'
import { SpringValue } from 'react-spring'
import Image from 'next/image'
import { useTranslation, Trans } from 'next-i18next'

import { AnimatedStack } from '@/lib/springComponent'
import { getLocaleNumberString } from '@/lib/utils/format/string'

interface Props {
  title: string
  subject?: string
  attendeeCount?: number
  image: string | StaticImageData
  style?: Record<string, SpringValue | CSSObject>
  sx?: CSSObject
}

function CarouselItem({
  title,
  subject,
  attendeeCount,
  image,
  style,
  sx,
}: Props): React.ReactElement {
  const {
    t: tLanding,
    i18n: { language },
  } = useTranslation('landing')

  return (
    <AnimatedStack
      spacing="6"
      layerStyle="container"
      style={style}
      sx={{
        borderRadius: '4xl',
        bg: '#DAECFF',
        px: { base: 8, md: 10 },
        py: { base: 8, md: 10 },
        color: 'black',
        maxW: { base: 'full', md: '50%' },
        '&:first-of-type': { ml: { base: 0, md: '25%' } },
        '*': {
          userSelect: 'none',
          userDrag: 'none',
          pointerEvents: 'none',
        },
        ...sx,
      }}
    >
      <AspectRatio ratio={932 / 525}>
        <Image
          src={image}
          alt={title}
          placeholder={typeof image === 'string' ? undefined : 'blur'}
          layout="fill"
          objectFit="cover"
        />
      </AspectRatio>
      <Stack spacing="2">
        <Text sx={{ fontSize: { base: 'xl', md: '2xl' }, fontWeight: 'bold' }}>
          {title}
        </Text>
        {subject && (
          <Text
            sx={{
              fontSize: {
                base: 'lg',
                md: 'xl',
              },
            }}
          >
            <Trans
              t={tLanding}
              i18nKey="legalSeminarExp.subject"
              values={{ subject }}
            >
              <Text as="strong" sx={{ fontWeight: 'medium' }}>
                Subject
              </Text>

              <Text as="strong" sx={{ fontWeight: 'medium' }}>
                Emphasis 1
              </Text>

              <Text as="strong" sx={{ fontWeight: 'medium' }}>
                Emphasis 2
              </Text>
              {subject}
            </Trans>
          </Text>
        )}
        {typeof attendeeCount === 'number' && (
          <Text
            sx={{
              fontSize: {
                base: 'lg',
                md: 'xl',
              },
            }}
          >
            <Trans
              t={tLanding}
              i18nKey="legalSeminarExp.attendeeCount"
              values={{
                count: getLocaleNumberString(attendeeCount, 0, language),
              }}
            >
              ผู้เข้าฟังสูงสุดกว่า
              <Text as="strong" sx={{ fontWeight: 'medium' }}>
                1,000
              </Text>
              คน
            </Trans>
          </Text>
        )}
      </Stack>
    </AnimatedStack>
  )
}

export default CarouselItem
