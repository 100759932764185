import { Text, VStack, Box } from '@chakra-ui/react'
import { Trans, useTranslation } from 'next-i18next'
import { useInView } from 'react-intersection-observer'

import CarouselComponent, {
  CarouselContextProvider,
} from '@/features/landing/components/Carousel'

function Carousel(): React.ReactElement {
  const { t: tLanding } = useTranslation('landing')

  const { inView, ref } = useInView({
    threshold: 0.1,
  })

  return (
    <CarouselContextProvider autoRotate={inView}>
      <Box
        ref={ref}
        sx={{
          bg: 'gray.100',
          overflowX: 'hidden',
        }}
      >
        <VStack
          spacing="4"
          layerStyle="container"
          sx={{
            alignItems: 'stretch',
            color: 'white',
          }}
        >
          <VStack
            layerStyle="landingSectionHeading"
            sx={{
              alignItems: 'center',
              color: 'black',
            }}
          >
            <Text as="h1" textStyle="landingSectionTitle">
              <Trans t={tLanding} i18nKey="legalSeminarExp.title">
                <Text as="span" sx={{ color: 'primary.400' }}>
                  งานสัมนาและ Training
                </Text>
                ที่ทีมนักกฏหมายของเราเข้าร่วม
              </Trans>
            </Text>
          </VStack>
          <CarouselComponent />
        </VStack>
      </Box>
    </CarouselContextProvider>
  )
}

export default Carousel
